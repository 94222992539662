




import Vue from "vue";
import Maintenance from "../components/Maintenance.vue";

export default Vue.extend({
  name: "Home",

  components: {
    Maintenance,
  },
});
